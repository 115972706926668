<script setup>
import {ref} from "vue";

defineProps({
    level: {
        type: String,
        default: 'info',
    },
    hideButton: {
        type: Boolean,
        default: true,
    },
    theme: {
        type: String,
        default: 'solid',
    },
    message: String|Array,
})

let hidden = ref(false)

const close = () => {
    hidden.value = true
}

</script>

<template>
    <div v-if="!hidden" :class="{
        'bg-red-700': level === 'danger' && theme === 'solid',
        'bg-yellow-700': level === 'warning' && theme === 'solid',
        'bg-indigo-700': level === 'info' && theme === 'solid',
        'bg-green-700': level === 'success' && theme === 'solid',
        'text-red-800 border-red-300 bg-red-50': level === 'danger' && theme === 'light',
        'text-yellow-800 border-yellow-300 bg-yellow-50': level === 'warning' && theme === 'light',
        'text-indigo-800 border-indigo-300 bg-indigo-50': level === 'info' && theme === 'light',
        'text-green-800 border-green-300 bg-green-50': level === 'success' && theme === 'light',
        'py-5 px-6 text-white': theme === 'solid',
        'py-3 px-5 border rounded': theme === 'light',
    }" class="mb-4 text-base mb-3" role="alert">
        <div v-if="hideButton">
            <div class="flex justify-between items-center">
                <div>
                    <slot v-if="$slots.default"/>
                    <div v-else-if="Array.isArray(message)" v-for="line in message">{{ line }}</div>
                    <template v-else>{{ message }}</template>
                </div>
                <button type="button" @click="close">
                    x
                </button>
            </div>
        </div>
        <template v-else>
            <slot/>
            <div v-if="Array.isArray(message)" v-for="line in message">{{ line }}</div>
            <template v-else>{{ message }}</template>
        </template>
    </div>
</template>
