<script setup>
import { Link } from '@inertiajs/vue3';
const props = defineProps({
    links: Array,
})

const getStyles = (isActive) => {
    return isActive ? 'border-primary-800 text-black' : 'border-transparent text-gray-700'
}

</script>
<template>
    <Link v-for="link in links"
       class="py-5 px-3 block border-t-4 max-md:border-t-0 max-md:border-l-2 hover:border-gray-400 hover:text-gray-800 active:text-black transition duration-300"
       :class="getStyles(link.active)" :href="link.href">
        {{ link.caption }}
    </Link>
</template>
