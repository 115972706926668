<script setup>
import logoUri from '/resources/media/logo.png'
import Steps from "@/Components/Elements/Steps.vue";
import FooterLinks from "@/Components/FooterLinks.vue";
import Alert from "@/Components/Alert.vue";
import {router, Link, Head} from "@inertiajs/vue3";
import NavbarTop from "@/Components/Navbar/NavbarTop.vue";
import favicon16 from '/resources/media/favicons/favicon-16x16.png';
import favicon16White from '/resources/media/favicons/favicon-16x16-white.png';
import favicon32 from '/resources/media/favicons/favicon-32x32.png';
import favicon32White from '/resources/media/favicons/favicon-32x32-white.png';
import TimerAlert from '@/Components/TimerAlert.vue'
import { ref, watch } from 'vue'

const flag = (lang) => {
    return new URL(`/resources/media/flags/${lang}.png`, import.meta.url).href
}

const currentLocale = document.querySelector('html')?.getAttribute('lang') ?? 'de'

const externalUrl = (uri = '', lang = currentLocale) => {
    return router.page.props.legacyUrl + '/' + lang + '/' + uri
}

const props = defineProps({
    backgroundImage: String,
})
const timerAlertExpired = ref(false)

const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

</script>

<template>
    <Head>
        <link v-if="!darkMode" rel="icon" type="image/png" sizes="16x16" :href="favicon16">
        <link v-else rel="icon" type="image/png" sizes="16x16" :href="favicon16White">
        <link v-if="!darkMode" rel="icon" type="image/png" sizes="32x32" :href="favicon32">
        <link v-else rel="icon" type="image/png" sizes="32x32" :href="favicon32White">
    </Head>
    <div class="flex flex-col bg-cover bg-fixed bg-right min-h-screen lg:pt-20" :style="backgroundImage ? 'background-image: url(\'' + backgroundImage + '\')' : ''">
        <div class="mb-5 w-full lg:fixed lg:top-0 z-10 bg-white border-b shadow-lg shadow-black-500/40">
            <div class="container">
                <div class="flex max-lg:flex-col max-md:py-3 max-md:gap-3 lg:justify-between max-md:justify-center items-center">
                    <div class="max-lg:pb-3 max-lg:border-b max-lg:w-full">
                        <a :href="route('booking.index')">
                            <img :src="logoUri" alt="IranAir.at" class="h-16">
                        </a>
                    </div>
                    <div class="max-md:w-full flex justify-between items-center">
                        <template v-if="$page.props.navbar">
                            <NavbarTop :links="$page.props.navbar"/>
                        </template>
                        <div class="flex gap-6 text-gray-600">
                            <Link v-if="$page.props.language" :href="$page.props.language.url" class="flex gap-1 items-center hover:text-black transition duration-200"><img :src="flag($page.props.language.flag)" :alt="$page.props.language.name"><span>{{ $page.props.language.name }}</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container grow flex flex-col">

            <TimerAlert v-if="$page.props.timerAlert" v-model="timerAlertExpired" :data="$page.props.timerAlert" :timer-variant="'rounded'" class="mt-3"/>
            <Steps v-if="$page.props.steps" :items="$page.props.steps" class="mb-5"/>

            <div class="grow flex flex-col">
                <Alert v-if="$page.props.flushMessage.error" level="danger" :message="$page.props.flushMessage.error"></Alert>
                <Alert v-if="$page.props.flushMessage.warning" level="warning" :message="$page.props.flushMessage.warning"></Alert>
                <Alert v-if="$page.props.flushMessage.info" level="info" :message="$page.props.flushMessage.info"></Alert>
                <Alert v-if="$page.props.flushMessage.success" level="success" :message="$page.props.flushMessage.success"></Alert>
                <div class="py-5 grow flex flex-col">
                    <slot name="top-content" :timerAlertExpired="timerAlertExpired"/>
                </div>
            </div>
        </div>
    </div>

    <slot name="bottom-content" :timerAlertExpired="timerAlertExpired"/>

    <div class="py-10 bg-primary-500 text-gray-300 justify-self-end">
        <div class="container">
            <div class="grid md:grid-cols-3 md:gap-5 max-md:gap-8 max-md:text-center">
                <div>
                    <div class="font-bold text-lg mb-3 text-white">{{ $page.props.commonTrans.contact }}</div>
                    <div>0043-1-586 56 01</div>
                    <div>ticket@iranair.at</div>
                    <div>1010 Wien, Opernring 1</div>
                    <FooterLinks :links="[
                        {
                            caption: $page.props.commonTrans.contact,
                            href: route('contact'),
                        }
                    ]"/>
                </div>
                <div>
                    <div class="font-bold text-lg mb-3 text-white">{{ $page.props.commonTrans.openingHours }}</div>
                    <div>{{ $page.props.commonTrans.monday }} - {{ $page.props.commonTrans.friday }}</div>
                    <div>9:30 - 14:00</div>
                </div>
                <div>
                    <div class="font-bold text-lg mb-3 text-white">{{ $page.props.commonTrans.helpfulLinks }}</div>
                    <FooterLinks :links="[
                        {
                            caption: $page.props.commonTrans.privacyPolicy,
                            href: route('privacy-policy'),
                        },
                        {
                            caption: $page.props.commonTrans.termsAndConditions,
                            href: route('terms-conditions'),
                        },
                        {
                            caption: $page.props.commonTrans.imprint,
                            href: route('imprint'),
                        },
                    ]"/>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center bg-white py-10">
        <div>
            {{ $page.props.commonTrans.copyright[0] }}
            <a class="border-b border-dotted text-blue-400 hover:text-primary-500" :href="route('booking.index')">{{ $page.props.commonConfig['app.name'] }}</a>.
            {{ $page.props.commonTrans.copyright[1] }}
        </div>
    </div>
</template>
