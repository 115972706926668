<script setup>
import NavbarTopLink from "@/Components/Navbar/NavbarTopLink.vue";
import {ref} from "vue";
defineProps({
    links: Array,
})

let display = ref(false)

const changeDisplay = () => {
    console.log('change')
    display.value = !display.value
}

</script>

<template>
    <div class="max-md:w-60">
        <button type="button" :class="{'rounded': !display, 'rounded-t': display}" class="md:hidden bg-primary-500 text-white p-3" @click="changeDisplay">
            <svg v-if="!display" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                <path fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10zm0 5.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
        </button>
        <div class="relative w-full">
            <div :class="{'max-md:hidden': !display}"
                 class="grow flex justify-end content-center max-md:flex-col max-md:absolute max-md:w-60 max-md:shadow-lg max-md:top-100 max-md:bg-white">
                <NavbarTopLink :links="links" />
                <div class="my-5 mx-5 border-r border-gray-400 max-md:hidden"></div>
            </div>
        </div>
    </div>
</template>
