<script setup>
import {Link} from "@inertiajs/vue3";
import Icon from "@/Components/Icons/Icon.vue";

const props = defineProps({
    links: Array,
})

const getStyles = (isActive) => {
    return isActive ? 'bg-primary-800 text-white' : 'text-blue-400'
}

</script>
<template>
    <div v-for="link in links">
        <Icon symbol="angle-right" class="inline-block text-primary-500"/>
        <Link
           class="text-gray-200 hover:text-gray-400 transition duration-200"
           :class="getStyles(link.active)" :href="link.href">
            {{ link.caption }}
        </Link>
    </div>
</template>
