<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    items: Array,
})

let activeFound = -1
props.items.forEach(function (item, key) {
    if (route().current(item.route) && key > activeFound) {
        activeFound = key
    }
})

const isActive = (key) => {
    return key <= activeFound
}

const generalStyles = {'text-center p-5 flex flex-col items-center border-b border-b-4': true};

</script>

<template>
    <div v-if="activeFound >= 0" id="steps">
        <div class="overflow-x-auto border border-gray-300 rounded">
            <div class="min-w-max grid grid-cols-4">
                <template v-for="(item, key) in items">
                    <template v-if="isActive(key) && item.active">
                        <Link v-if="item.route"
                              :href="route(item.route, item.params)"
                              :class="generalStyles" class="text-primary-300 border-primary-300">
                            <div>{{ $page.props.commonTrans.step }} {{ key+1 }}</div>
                            <div class="xl:text-2xl lg:text-2xl">{{ item.title }}</div>
                        </Link>
                        <div v-else :class="generalStyles" class="text-gray-400 border-primary-300">
                            <div>{{ $page.props.commonTrans.step }} {{ key+1 }}</div>
                            <div class="xl:text-2xl lg:text-2xl">{{ item.title }}</div>
                        </div>
                    </template>
                    <div v-else :class="{...generalStyles, ...{'border-primary-300': isActive(key) && !item.active}}" class="text-gray-400 border-transparent">
                        <div>{{ $page.props.commonTrans.step }} {{ key+1 }}</div>
                        <div class="xl:text-2xl lg:text-2xl">{{ item.title }}</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
