<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
import Alert from '@/Components/Alert.vue'
import InertiaButton from '@/Components/InertiaButton.vue'

const props = defineProps({
    modelValue: Boolean,
    data: {
        expiredDatetime: Date,
        messageOngoing: String,
        messageExpired: String,
        labelLink: String,
        theme: String,
        labelDays: String,
        labelHours: String,
        labelMinutes: String,
    },
})

const emits = defineEmits(['update:modelValue'])

const calcRemainingTime = () => {
    const diff = (new Date(props.data.expiredDatetime)).getTime() - (new Date()).getTime()
    const remaining = diff > 0 ? Math.floor(diff / 1000) : 0
    emits('update:modelValue', remaining <= 0)
    return remaining
}

const remainingTimeInSeconds = ref(calcRemainingTime());
const timer = ref(null);

const startCountdown = () => {
    timer.value = setInterval(() => {
        remainingTimeInSeconds.value = calcRemainingTime()
    }, 1000)
};

onMounted(startCountdown)
onUnmounted(() => {
    if (timer.value) {
        clearInterval(timer.value)
    }
});

const remainingDateTimeReadable = computed(() => {
    const days = Math.floor(remainingTimeInSeconds.value / 86400);
    const hours = Math.floor((remainingTimeInSeconds.value % 86400) / 3600);
    const minutes = Math.floor((remainingTimeInSeconds.value % 3600) / 60);
    const seconds = (remainingTimeInSeconds.value % 60);
    if (days === 1) {
        return { days: 0, hours: hours+24, minutes, seconds };
    }
    return { days, hours, minutes, seconds };
});

const remainingTimeMessage = computed(() => {
    const time = () => {
        if (remainingDateTimeReadable.value.days > 0) {
            return remainingDateTimeReadable.value.days + ' ' + props.data.labelDays
        } else if (remainingDateTimeReadable.value.hours > 2) {
            return remainingDateTimeReadable.value.hours + ' ' + props.data.labelHours
        } else if (remainingDateTimeReadable.value.hours > 0) {
            return `${remainingDateTimeReadable.value.hours}:${remainingDateTimeReadable.value.minutes.toString().padStart(2, '0')}:${remainingDateTimeReadable.value.seconds.toString().padStart(2, '0')} ${props.data.labelMinutes}`
        } else {
            return `${remainingDateTimeReadable.value.minutes.toString().padStart(2, '0')}:${remainingDateTimeReadable.value.seconds.toString().padStart(2, '0')} ${props.data.labelMinutes}`
        }
    }
    return props.data.messageOngoing.replace(':time', time)
});

const displayMessage = ref(false)

const toggleMessage = () => {
    displayMessage.value = !displayMessage.value
}

</script>

<template>
    <div>
        <template v-if="remainingTimeInSeconds > 0">
            <div class="flex items-center transition-all" :class="{
                    'rounded-t-md': data.theme === 'related_to_next',
                    'rounded-md': data.theme === 'standalone',
                    'bg-gray-200': displayMessage,
                }">
                <div class="px-4 py-1 bg-primary-500 text-white text-center shadow-lg flex gap-2 cursor-pointer" :class="{
                    'rounded-t-md': data.theme === 'related_to_next',
                    'rounded-md': data.theme === 'standalone',
                }" @click="toggleMessage">
                    <div class="text-xl" v-if="parseInt(remainingDateTimeReadable.days) > 0">+{{ remainingDateTimeReadable.days }} {{ data.labelDays }}</div>
                    <div class="text-xl" v-else-if="parseInt(remainingDateTimeReadable.hours) > 2 ">+{{ remainingDateTimeReadable.hours }} {{ data.labelHours }}</div>
                    <template v-else>
                        <div class="text-xl" v-if="parseInt(remainingDateTimeReadable.hours) > 0 ">{{ remainingDateTimeReadable.hours }}</div>
                        <div v-if="parseInt(remainingDateTimeReadable.hours) > 0 ">:</div>
                        <div class="text-xl">{{ remainingDateTimeReadable.minutes }}</div>
                        <div>:</div>
                        <div class="text-xl">{{ remainingDateTimeReadable.seconds }}</div>
                    </template>
                </div>
                <div class="ms-3 cursor-pointer text-primary-300 hover:text-slate-700" @click="toggleMessage">
                    <svg v-if="!displayMessage" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </div>
                <div class="ps-3 text-primary-500 transition-all" :class="{
                    'hidden': !displayMessage,
                }">{{remainingTimeMessage}}</div>
            </div>
        </template>
        <Alert v-else level="danger" :hide-button="false">
            {{ data.messageExpired }}
            <InertiaButton v-if="data.labelLink" :href="route('booking.index')" level="alert" :size="2">{{ data.labelLink }}</InertiaButton>
        </Alert>
    </div>
</template>
